@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap/scss/mixins";

@import 'abstract/variables';
@import 'abstract/helpers';
@import 'abstract/typography';

@import 'vendors/slick';

@import 'components/status';
@import 'components/date_selector';
@import 'components/dialog';
@import 'components/buttons';
@import 'components/tables';
@import 'components/menu';
@import 'components/list';
@import 'components/stepper';
@import 'components/toolbar';
@import 'components/expansion-panel';
@import 'components/cards';
@import 'components/form';
@import 'components/chips';
@import 'components/panels';

@import 'layout/sidebar';
@import 'layout/main-content';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/v4-shims';

body {
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Nunito', sans-serif;
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.search-form-field.mat-form-field-appearance-outline {
  //margin-top: -4px;

  @include media-breakpoint-down(lg) {
    //display: none;
  }

  .mat-toolbar-row &{
    margin-top: 0;
  }

  &.mat-focused {
    background: white;
  }

  &.short {
    width: 128px;

    @include media-breakpoint-up(xxxl) {
      width: auto;
    }
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 0;
  }

  .mat-form-field-suffix {

    button {
      position: relative;
      top: 8px;
      right: -8px;
    }
  }

  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
    height: 48px;

    .cs-card-filters &{
      background: white;
      border-radius: 4px;
    }
  }
  .mat-form-field-infix {
    padding: 1.25rem 0 1rem;
    border: none;
    /* height: auto; */
  }
  .mat-form-field-flex {
    /* height: 0; */
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }
}
.active {
  color: #59BB93;
}
.draft {
  color: #0182D5;
}
.scheduled {
  color: #E97C08;
}
.monthly_cap {
  color: #A1359E;
}
.daily_cap {
  color: #D59B01;
}
.paused {
  color: #999999;
}
.completed_by_budget {
  color: #0153D5;
}
.completed_by_date {
  color:#01B7D5;
}
.extended {
  color: #DA5E77;
}