@import "../abstract/variables";
body {

	.mat-expansion-panel {

		&:not([class*='mat-elevation-z']) {
			box-shadow: $box-shadow;
			border: 1px solid $border-color;
		}
	}

	.full-panel-body {

		.mat-expansion-panel-body {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.mat-expansion-panel-header {
		border-bottom: 1px solid $border-color;
		font-weight: 500;
	}
}
