@import "../abstract/variables";

.status {

  &-plate-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
  }

  &-circle-group {
    display: flex;
    align-items: center;

    .status-circle {
      display: flex;
      align-items: center;
      width: 50px;

      .fa-circle {
        font-size: 14px;
        margin-right: .5rem;
      }

      + .status-circle {
        margin-left: 1rem;
      }
    }
  }

  &-plate {
    width: 75px;
    min-height: 48px;
    display: inline-block;
    text-align: center;
    padding: 6px 8px;
    background: $white;
    line-height: 1.125;
    border: 2px solid;
    border-radius: $border-radius;
    cursor: pointer;
    transition: all .33s ease-in-out;
    position: relative;
    top: 9px;

    &.status-plate-active {
      color: $white;
      border-color: transparent;
    }

    .status-plate-group & {

      @include media-breakpoint-up(lg) {
        margin: 0 7px 0 0;

        .publisher-app & {
          margin: 0 4px 0 0;
        }
      }
    }

    > small {
      font-size: 12px;
    }

    p {
      margin: 0;
    }

    &--blue {
      //color: $blue-light;

      &:hover {
        //background: rgba($blue-light, .125);
      }

      &.status-plate-active {
        //background: $blue-light;
      }
    }

    &--green {
      color: $green;

      &:hover {
        background: rgba($green, .125);
      }

      &.status-plate-active {
        background: $green;
      }
    }

    &--red {
      //color: $red;

      &:hover {
        //background: rgba($red, .125);
      }

      &.status-plate-active {
        //background: $red;
      }
    }

    &--orange {
      color: $orange;

      &:hover {
        background: rgba($orange, .125);
      }

      &.status-plate-active {
        background: $orange;
      }
    }

    &--gray {
      color: $bluegray;

      &:hover {
        background: rgba($bluegray, .125);
      }

      &.status-plate-active {
        background: $bluegray;
      }

      &.status-plate--total {
        &:hover {
          cursor: default;
          background: $white;
        }
      }
    }

    &--blue {
      color: $blue;

      &:hover {
        background: rgba($blue, .125);
      }

      &.status-plate-active {
        background: $blue;
      }

      &.status-plate--total {
        &:hover {
          cursor: default;
          background: $white;
        }
      }
    }

    &--lightgray {
      //color: $gray-light;

      &:hover {
        //background: rgba($gray-light, .125);
      }

      &.status-plate-active {
        //background: $gray-light;
      }
    }
  }

  &-text {
    line-height: 1;
  }

  &-circle {

    &--blue {
      //color: $blue-light;
    }
    &--green {
      //color: $green;
    }
    &--red {
      //color: $red;
    }
    &--orange {
      //color: $orange;
    }
    &--gray {
      //color: $bluegray;
    }

    + span {
      margin-left: 6px;
    }
  }
}
