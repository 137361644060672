@import "../abstract/variables";

.metric-panel {
	font-size: 18px;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@include media-breakpoint-up(md) {
		flex-direction: column;
		align-items: center;
	}

	.metric-panel-icon {
    flex: 0 0 50px;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 40px;
		margin: 1rem 0;

    @include media-breakpoint-up(md) {
      margin: 1rem;
    }

		&--green {
			background: rgba($success, .125);
			color: $success;
		}
		&--blue {
			background: rgba($primary, .125);
			color: $primary;
		}

		&--red {
			background: rgba($warning, .125);
			color: $warning;
		}
		&--orange {
			background: rgba($danger, .125);
			color: $danger;
		}
	}

	.metric-panel-data {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 1rem;
		flex: 1;

		@include media-breakpoint-up(lg) {
			padding-left: 0;
			flex: 0;
			align-items: center;
		}
	}

	strong {
		font-size: 1rem;
		color: $primary-color;
	}

	small {
		color: $text-muted;
    font-size: 14px;
	}
}
