@import "../abstract/variables";

.mat-chip-list-stacked {
	display: flex;
	flex-wrap: wrap;

	> * {
		width: 100%;
	}
}

.mat-basic-chip {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: .25rem;
	padding: 0.75rem 1.25rem;
	background: $chips-background;
	border: 1px solid $border-color;
	border-radius: 50px;
	line-height: 1;

	.fas {
		margin: 0 -.5rem 0 1rem;
	}
}
