.toolbar-form-field.mat-form-field-appearance-outline {

  .mat-form-field-outline {
    top: 0;
  }

  .mat-form-field-wrapper {
    margin-bottom: 0;
    padding: 0;
  }

  .mat-form-field-infix {
    border-top: none;
  }
  .mat-select-arrow-wrapper {
    transform: translateY(0);
  }
}


