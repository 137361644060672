@import "../abstract/variables";

.main-content{
  padding: 1rem 0;

  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }

  .main-content-wrapper {
    border-radius: 5px;
    overflow: hidden;
    background-color: $white;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

    .main-content-header {
      height: 64px;
      background-color: $white;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: $primary-color;
      border-bottom: $border;

      h2 {
        margin: 0;
      }

      button {
        margin-left: auto;
      }
    }

    .main-content-body {
      padding: 20px;
    }
  }
}
