body {

  .mat-dialog-title {

    &.has-action {
      margin: -1.5rem -1.5rem 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }

    .mat-form-field {
      font-size: 1rem;
    }

    .mat-dialog-header-search {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }

  .mat-dialog-subtitle {
    display: block;
    font-size: 14px;
    line-height: 1;
  }

  .mat-dialog-content {

  }

  .mat-dialog-actions {
    justify-content: center;
    margin-top: 1rem;
  }

  .alert-dialog-wrapper {

    .mat-dialog-title {
      background: $danger;
      margin: -1.5rem -1.5rem 1.5rem;
      padding: 1.5rem;
      color: white;
    }
  }

}
