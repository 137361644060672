body {
  h1 {
    font-size: 2rem; // 32px
    line-height: 2.75rem; // 43px
    //color: $main-color;
    font-weight: normal;
  }
  h2 {
    font-size: 1.5rem; // 24px
    line-height: 2rem; // 32px
    //color: $main-color;
    font-weight: normal;
  }
  h3 {
    font-size: 1.125rem; // 18px
    line-height: 1.5rem; // 24px
    //color: $main-color;
    font-weight: normal;
  }
}
