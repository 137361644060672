button {
  transition: all 0.2s;

  &.iconed .mat-button-wrapper > {
    .mat-icon,
    [class*="fa-"]{
      margin: 0 5px;
      padding-bottom: 3px;
    }
  }

  &.iconed-mr-7 {
    margin-right: 7px;
  }

  &.button-primary {
    background-color: $primary-color;
    color: $white;
    &:hover {
      background-color: darken($primary-color, 10%);
    }
    &:active {
      background-color: darken($primary-color, 10%);
    }
    &:disabled {
      background-color: $light-tgf;
    }
  }
  i {
    font-size: 11px;
  }

  &.button-warning {
    background-color: $warning;
    color: $white;
    &:hover {
      background-color: darken($warning, 10%);
    }
    &:active {
      background-color: darken($warning, 10%);
    }
    &:disabled {
      background-color: $light-tgf;
    }
  }
  i {
    font-size: 11px;
  }
}

