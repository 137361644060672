@import "../abstract/variables";

.date-selector {
	position: relative;

	.date-preview {
		display: flex;
		align-items: center;
	}

	.date-range-dates {}

	.date-range-icon {
		margin-left: 1rem;
	}

  .date-selector-arrow {

    //.co-dash &{
    //  display: none;
    //
    //  @include media-breakpoint-up(xxl) {
    //    display: block;
    //  }
    //}
  }
}

.date-chooser {
	display: flex;
	flex-direction: column;

  &.hovering {}

	.date-chooser-hovering & {
    background: $white;
    position: absolute;
    z-index: 100000;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: $box-shadow;
  }

	.date-chooser-right-aligned & {
		right: 0;
		left: auto;
		transform: none;
	}

  .date-chooser-inline &{

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

	@media only screen and (min-width: 1025px) {
		width: auto;
		flex-direction: row;
	}

	@media only screen and (min-width: 1025px) and (max-width: 1199px) {

		.reporting & {

			left: auto;
			right: -50%;

			.custom-date-container {
				// Place date pickers to right side so they are closer to initial click location
				order: 2;
			}

		}

	}

	.mat-icon-button {
		padding: 0;
		line-height: 40;
	}
}

.custom-date-container {
  margin-right: 1rem;
  //border-radius: $border-radius;

  @media only screen and (min-width: 1025px) {
    width: 175px;
  }

  .date-chooser-hovering &{
    margin-right: 0;
    padding: 1rem;
    border-right: 1px solid $border-color;
  }

  .date-chooser-inline & {
    width: auto;
    display: flex;
    flex-direction: column;
    margin: 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    > .mat-form-field {
      margin-right: 30px;
    }
  }
}

.date-range-container {
	display: flex;
	flex-wrap: wrap;

  @media only screen and (min-width: 1025px) {
    width: 250px;
  }

  .date-chooser-inline & {
    width: auto;
    align-items: center;
  }

	> button {
		flex: 0 0 50%;

    .date-chooser-inline & {
      flex: 0 0 auto;
    }
	}
}


.report-builder-date-chooser {

  .custom-date-container {}
  .date-range-container {
    width: auto;

    button {
      flex: 1 1 50%;
      align-self: center;
      margin: 0;

      @include media-breakpoint-up(md) {
        flex: 1 1 25%;
      }
    }
  }
}

cm-widget-alerts .date-chooser {
  flex-direction: row;

  .custom-date-container {
    width: 130px;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: column;

    .custom-date-container {
      width: 100%;
      order: 1;
      margin-top: 1rem;
    }

    .date-range-container {
      width: 100%;
    }
  }
}
