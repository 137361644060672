// ======================== COLORS ======================
$navy-tgf: #004f82;
$orange-tgf: #fc7c08;
$light-tgf: #cccccc;
$lighter-tgf: #e0dfdf;

$white: #ffffff;
$primary-color: #004f82;
$sidebar-background: $white;
$border-color: #0000001a;
$font-color: #494949;
$warning: $orange-tgf;
$danger: #dc3545;
$success: #4BB543;
$primary: $primary-color;
$bluegray: #49545E;
$bluegray-lighter: #7F8FA4;
$box-shadow-z: 0 3px 0 0 $border-color, 0 3px 15px 0 $border-color;
$chips-background: #f2f4f6;

// ======================== PX ======================

$header-height: 70px;
$mobile-top-bar-height: 50px;
$sidebar-width: 260px;
$sidebar-form-width: 50vw;
$sidebar-width-collapsed: 0px;
$border-radius: 4px;
$border: 1px solid $border-color;
$table-cell-padding-x: 6px;

// ======================== BOOTSTRAP SCSS ======================

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$grid-gutter-width: 32px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1600px
);

$container-max-widths: (
  xxl: 1280px
);

$sizes: () !default;
$sizes: map-merge(
    (
      20: 20%,
      25: 25%,
      33: 33%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    ),
    $sizes
);

