@import "../abstract/variables";

dl {
  min-width: 100px !important;
  border-right: $border !important;
  display: inline-block;
  margin-left: 1.5rem !important;
  margin-bottom: 0.25rem !important;
  height: 50px;
  padding-right: 10px;

  &:first-child {
    margin-left: 0 !important;
  }

  dt {
    color: $font-color !important;
    height: 22px !important;
  }

  dd {
    font-size: 18px !important;

    button {
      position: relative;
      top: -15px;
    }

    mat-spinner {
      top: 5px !important;
    }
  }
}
