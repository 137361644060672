.mat-menu-select {

  .mat-menu-item {
    line-height: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    overflow: visible;

    .mat-checkbox-layout {
      margin: 0;
    }

    .mat-checkbox-label {
      line-height: 25px;
    }
  }
}
