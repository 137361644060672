@import "variables";

.hand {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: right;
}

.text-primary {
  color: $primary-color !important;
}

.text-grey {
  color: $light-tgf !important;
}

.center-div {
  margin: 0 auto;
}

.bg-warning {
  background-color: $orange-tgf !important;
  color: $white !important;
}

.bg-warning:disabled {
  background-color: rgba($orange-tgf, 0.4) !important;
}

.w-10 {
  width: 10% !important;
}

.w-99 {
  width: 99% !important;
}

.link {
	color: $primary;
	cursor: pointer;
}
