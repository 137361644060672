.slick-prev,
.slick-next {
  background-color: white !important;
  border: solid 2px $orange-tgf;
  z-index: 999;
  width: 62px !important;
  height: 62px !important;
  display: inline-block;

  &::before,
  &::after {
    color: $orange-tgf !important;
    font-size: 48px !important;
  }
}

.slick-prev {
  left: -62px !important;
}

.slick-next {
  right: -62px !important;
}

.slick-dots {
  li {
    button {
      &::before {
        font-size: 16px !important;
        color: $orange-tgf !important;
      }
    }
  }
}
