.mat-stepper-horizontal {

 .mat-step-header.cdk-keyboard-focused,
 .mat-step-header.cdk-program-focused,
 .mat-step-header:hover,
 .mat-step-header:hover:not([aria-disabled]){
   background: none;
 }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 16px;
  }

}

.mat-step-header {

 .mat-step-text-label {
   font-weight: bold;
 }
}

.mat-step-icon-state-edit {

 .mat-step-icon-content {
   font-size: 10px;
 }
}

.stepper-step-title {
 margin-bottom: 20px;
 font-size: 16px;
 font-weight: 600;
}

.stepper-actions {
 display: flex;
 align-items: center;
 justify-content: space-between;
 margin-top: 36px;

 .mat-button,
 .mat-raised-button {
   padding: 0 2rem;
 }
}
