@import "../abstract/variables";

body {

  .mat-card {

    &:not([class*='mat-elevation-z']) {
      box-shadow: $box-shadow;
      border: 1px solid $border-color;
    }

    .mat-card-header {
      flex-wrap: wrap; // header-info new line for mobile
      align-items: center;
      margin-bottom: 1rem;
      min-height: 40px;
    }

    .mat-card-title {
      font-size: 18px;
      line-height: 1.5rem;
      margin-bottom: 0;
    }

    .mat-card-header-text {
      margin: 0;
      flex-grow: 1;
    }

    .mat-card-header-actions {
      display: flex;
      align-items: center;

      button + * {

      }
    }

    .mat-card-header-info {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        order: 2;
        flex: 1 1 100%;
        margin-top:  1rem;
      }
    }

    .mat-card-content {

      > hr {
        margin: 0;
      }

      &--full {
        margin: 0 -1rem;
      }
    }

    .card-message {
      padding: 3rem 2rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
