@import "../abstract/variables";

.fa-file-csv,
.fa-file-excel {
  position: relative;
  top: 4px;
  cursor: pointer;
}

.table-scroll-wrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  max-height: 72vh;
}

.mobile-label {
  display: none;
}

.mat-paginator {
  border-radius: 0 0 $border-radius $border-radius;
}

// ======================== MATERIAL OVERWRITE/FIXES ======================


  table {
    thead {
      border-bottom: $border;
    }
    tbody {
      tr {
        &:nth-child(even) {
          background-color: $white;
        }
      }
      td {
        &.mat-cell {
          color: $font-color;
          border-bottom: $border;
          border-right: $border;
        }
      }
    }
  }


body {
  .mat-header-cell,
  .mat-footer-cell,
  .mat-cell {
    line-height: 1.25;
    padding: 0 $table-cell-padding-x;
    font-size: 13px;
  }

  .mat-header-cell {
    &.mat-cell--number {
      justify-content: flex-end;

      .mat-sort-header-button {
        text-align: right;
      }
    }
  }

  .mat-cell,
  .mat-footer-cell {
    [class*='fa-'] {
      font-size: 1rem;
    }

    &.mat-cell--number {
      justify-content: flex-end;
      padding-right: 1.5rem;
    }
  }

  /* When table needs to scroll horizontally on smaller screens,
      row does not expand to full with of content, so the border looks broken.
      Setting the border-bottom to table cell fixes that issue.
    */
  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom: none;

    mat-cell,
    mat-footer-cell,
    mat-header-cell {
      border-bottom: 1px solid $border-color;
    }

    &:hover {
      mat-cell {
        background: $white;
      }
    }
  }

  .mat-sort-header-arrow {
    color: $white !important;
  }
}

.min-width-50 {
  min-width: 50px;
}

.min-width-75 {
  min-width: 75px;
}

.min-width-100 {
  min-width: 100px;
}

.min-width-150 {
  min-width: 150px;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-250 {
  min-width: 250px;
}

.min-width-300 {
  min-width: 300px;
}

.cell-sm-font {
  font-size: 10px !important;
}

.actions {
  width: 120px !important;
  text-align: left !important;

  div {
    float: right;
  }

  &.actions-inner-table {
    width: 120px !important;
    text-align: center;

    div {
      float: none !important;
      display: block;
      text-align: center;
      padding-right: 5px;
    }
  }
}

.status-action {
  width: 100px !important;
  div {
    padding-left: 3%;
  }
  i {
    font-size: 1.4em !important;
  }
}

.copy {
  .ng-star-inserted {
    margin-left: -25px;
  }
}
