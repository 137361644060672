@import "../abstract/variables";

.mobile-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: $mobile-top-bar-height;
  padding: 0 5px;
  background: $primary-color;
  color: white;
  transition: all 0.33s ease-in-out;

  &.fixed {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  }
}

.mat-sidenav-content {
  padding-top: $mobile-top-bar-height;

  @media only screen and (min-width: 48em) {
    padding-top: 0;
  }
}

.mat-sidenav-container {
  min-height: 100vh;
}

body .mat-sidenav {
  position: fixed;
  background-color: $sidebar-background;
  color: $primary-color;
  &.sidenavForm {
    width: $sidebar-form-width;
  }
  &:not(.sidenavForm) {
    width: $sidebar-width;
    padding: 0;
    border-right: 1px solid $border-color;
    .sidenav-header {
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      height: $header-height;
      background-color: $white;
      border-bottom: 1px solid $border-color;
      margin: 0 16px;

      &:focus {
        outline: none;
      }
      .logo-wrap {
        display: flex;
        align-items: center;
      }
    }

    .sidenav-header-button {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $primary-color;
    }

    .sidenav-menu {
      //list-style: none;
      margin-top: 15px;
      padding: 0 10px;

      a {
        display: block;
        padding: 6px 0;
        color: inherit;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 18px;
        position: relative;
        transition: all 0.33s ease-in-out;
        text-decoration: none;

        &.active,
        &:hover {
          background-color: $white;
          color: $primary-color;
          text-decoration: none;

          > mat-icon {
            color: $primary-color;
          }
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .sidenav-submenu {
      //list-style: none;
      padding-left: 35px;
    }

    .active ul {
      display: block !important;
    }

    &.sideClosed {
      @include media-breakpoint-up(md) {
        margin-left: $sidebar-width-collapsed;
        visibility: visible !important;

        .sidenav-menu {
          //list-style: none;
          margin-top: 20px;
          padding: 0;

          a {
            text-align: right;
            i {
              width: 20px;
              margin-right: 6px;
              text-align: center;
            }
            span {
              font-size: 0;
            }
          }
        }
      }
    }
  }

  mat-expansion-panel {
    margin-bottom: 0;
    margin-top: 0;
  }

  mat-expansion-panel .panel-title{
    color: $font-color;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    display: flex;
    align-items: center;
    mat-icon {
      color: $font-color;
      margin-right: 7px;
      padding: 0;
    }
  }

  mat-expansion-panel-header {
    padding: 0 16px;

    &.mat-expanded {
      height: 52px;
    }
  }



  mat-expansion-panel {
    .panel-title {
      font-size: 15px;
    }

    &.active-title {
      .panel-title {
        font-weight: bold;
        color: $primary-color;
        height: 50px;

        mat-icon {
          color: $primary-color;
          padding: 0;
        }
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0 10px 0;
  }

  .mat-expansion-panel-body ul {
    margin: 0;
    color: #323232;
    list-style-type: disc;
    li.active a {
      color: $primary-color;
      font-weight: normal;
    }
    li.active::marker {
      color: $primary-color;
    }
  }

  ul li::marker {
    color: #d0d0d0;
  }
}
