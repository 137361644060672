.menu-info-list {
  list-style: none;
  padding: 0;
  margin: -8px 0;
  min-width: 200px;

  li {
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 14px;
    line-height: 1.25;
    word-break: break-all;
    border-bottom: 1px solid $border-color;

    small {
      flex: 0 0 130px;
      width: 60px; // Weird Chrome bug fix
      margin-right: 0.25rem;
      color: $text-muted;
      line-height: 1.5675;
    }
  }
}
